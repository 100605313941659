import {filter, isEmpty, map, mergeWith, partial, pipe} from 'ramda';
import {deepArrayDiff} from './Arrays';
import {isObject, isString} from './Common';
import {isNumber} from './Numbers';

export function pathTraverse(
  object: unknown,
  pathPrefix: (string | number)[] = [],
): (string | number)[][] {
  if (Array.isArray(object)) return object.flatMap((v, k) => pathTraverse(v, [...pathPrefix, k]));
  if (isObject(object))
    return Object.entries(object).flatMap(([k, v]) => pathTraverse(v, [...pathPrefix, k]));
  return [pathPrefix];
}
export function replaceLeaves(
  a: unknown,
  b: {[key: string | number]: string},
  defaultValue?: string,
): unknown {
  if (Array.isArray(a)) return a.map(i => replaceLeaves(i, b, defaultValue));
  //@ts-ignore should type
  if (isObject(a)) return map(v => replaceLeaves(v, b, defaultValue), a);
  if (!Object.keys(b).includes(a)) return a;
  //@ts-ignore
  return b[a] || defaultValue || null;
}
export function defaulting<T extends Record<string, unknown> = Record<string, unknown>>(
  defaultHash: T,
): [(arg0: T) => T, (arg0: T) => T] {
  return [partial(defaults, [defaultHash]), partial(undefaults, [defaultHash])];
}
export function defaults<T extends Record<string, unknown> = Record<string, unknown>>(
  defaultHash: T,
  target: T,
): T {
  return mergeWith((a, b) => a || b, target, defaultHash);
}
export function undefaults<T extends Record<string, unknown> = Record<string, unknown>>(
  defaultHash: T,
  target: T,
): T {
  // @ts-ignore
  return removeUndefinedValues(mapObject(target, (v, k) => (v === defaultHash[k] ? undefined : v)));
}
export function mapObject<V, R>(
  object: Record<string, V>,
  func: (arg0: V, arg1: string) => R,
): Record<string, R> {
  return Object.keys(object).reduce((acc, k) => {
    acc[k] = func(object[k], k);
    return acc;
  }, {});
}
export function removeUndefinedValues<V>(
  object: Record<string | number | symbol, Maybe<V>>,
): Record<string | number | symbol, V> {
  // @ts-ignore
  return Object.entries(object).reduce((acc, [key, value]) => {
    return value === undefined ? acc : {...acc, [key]: value};
  }, {});
}
export function removeNullValues<T extends Record<string, unknown>>(object: T): Partial<T> {
  return Object.entries(object).reduce((acc, [key, value]) => {
    return value === null ? acc : {...acc, [key]: value};
  }, {});
}
export function modify<T, O extends {[key: string]: unknown} | T[]>(
  prop: string | number,
  fn: (a: T) => T,
  obj: O,
): O {
  function isArray(x): x is unknown[] {
    return Array.isArray(x);
  }
  if (Number.isInteger(prop) && isArray(obj)) {
    //@ts-ignore
    const arr: T[] = [].concat(obj);
    arr[prop] = fn(arr[prop]);
    //@ts-ignore
    return arr;
  }

  const result = {...obj};
  result[prop] = fn(result[prop]);
  return result;
}
export function jsonDbParser(json: unknown): unknown {
  try {
    if (!json) return json;
    const ignoreTypes = ['number', 'bigint', 'boolean', 'symbol', 'undefined', 'function'];
    if (ignoreTypes.includes(typeof json)) return json;
    if (typeof json === 'string')
      return json.match(/^\d\d\d\d-\d\d-\d\d[\sT]\d\d:\d\d:\d\d$/)
        ? `${json.replace(' ', 'T')}+00:00`
        : json;
    // @ts-ignore
    return map(jsonDbParser, json);
  } catch {
    return json;
  }
}
export function isPojo(x: unknown): x is Record<string | number | symbol, unknown> {
  return (
    !!x &&
    !isString(x) &&
    !isNumber(x) &&
    !Array.isArray(x) &&
    typeof x === 'object' &&
    !(x instanceof Date)
  );
}
export function deepObjectDiff(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
): Record<string, unknown> {
  return pipe(
    () => obj1,
    Object.entries,
    map(([k, v]) => {
      const secondObjValue = obj2[k];
      if (isPojo(v) && isPojo(secondObjValue)) {
        const objDiff = deepObjectDiff(v, secondObjValue);
        if (!isEmpty(objDiff)) return [k, deepObjectDiff(v, secondObjValue)];
        return null;
      }
      if (Array.isArray(v) && Array.isArray(secondObjValue)) {
        const arrayDiff = deepArrayDiff(v, secondObjValue);
        if (!isEmpty(arrayDiff)) return [k, arrayDiff];
        return null;
      }
      if (v !== secondObjValue) {
        return [k, {from: v, to: secondObjValue}];
      }
      return null;
    }),
    filter(Boolean),
    Object.fromEntries,
  )() as Record<string, unknown>;
}
export function leftTableJoin<A, B>(
  onFn: (a: A, b: B) => boolean,
  leftTable: A[],
  rightTable: B[],
): {left: A; right: B}[] {
  return leftTable.flatMap(left =>
    rightTable.filter(right => onFn(left, right)).map(right => ({right, left})),
  );
}
