import Constants from './Constants';

export const TEST = 'test';
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';
export const ENVS = [TEST, DEVELOPMENT, STAGING, PRODUCTION] as const;
export type Env = typeof TEST | typeof DEVELOPMENT | typeof STAGING | typeof PRODUCTION;

export const BROWSER = 'browser';
export const IOS = 'ios';
export const ANDROID = 'android';
export const DEVICES = [BROWSER, IOS, ANDROID];
export type Device = typeof BROWSER | typeof IOS | typeof ANDROID;

export function ENV(): Env {
  // @ts-ignore process.env.ENV is Env
  if (typeof process !== 'undefined' && process.env.ENV) return process.env.ENV;
  // @ts-ignore __ENV__ is Env
  const env: Env = typeof __ENV__ === 'string' ? __ENV__ : DEVELOPMENT;
  return ENVS.includes(env) ? env : DEVELOPMENT;
}
export function TIMESTAMP(): string {
  if (typeof process !== 'undefined' && process.env.TIMESTAMP) return process.env.TIMESTAMP;
  return typeof __TIMESTAMP__ === 'string' ? __TIMESTAMP__ : new Date().toISOString();
}
export function isTest(): boolean {
  return ENV() === 'test';
}
export function isDevelopment(): boolean {
  return ENV() === 'development';
}
export function isStaging(): boolean {
  return ENV() === 'staging';
}
export function isProduction(): boolean {
  return ENV() === 'production';
}
export function isRunningOnDocker(): boolean {
  return process.env.DOCKER_RUN === 'true';
}
export function databaseName(): string | null | undefined {
  return {
    test: 'remoov_test.db',
    development: 'remoov_development.db',
    staging: 'remoov_staging.db',
    production: 'remoov_production.db',
  }[ENV()];
}
export function backgroundColor(): string | null | undefined {
  return {
    test: 'white',
    development: 'white',
    staging: 'white',
    production: null,
  }[ENV()];
}

export function hostEndpoint(): string {
  return {
    test: '',
    development: `http://localhost:5001`,
    staging: Constants.ENV_VAR.staging.REMOOV_URL,
    production: Constants.ENV_VAR.production.REMOOV_URL,
  }[ENV()];
}
export function remoovDomain(): string {
  return {
    test: '',
    development: 'localhost',
    staging: '.remoovit.com',
    production: '.remoovit.com',
  }[ENV()];
}

export function nodeApiEndpoint(): string {
  return `${remoovNodeEndpoint()}/api`;
}
export function s3Params(): {
  [key: string]: string;
} {
  return {
    test: {
      endpoint: 'https://remoov-test.s3.amazonaws.com/',
      awsKey: 'AKIAIPRQBRPBHXO7ERRA',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAyMC0xMi0zMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W3siYnVja2V0IjoicmVtb292LXRlc3QifSxbInN0YXJ0cy13aXRoIiwiJGtleSIsIiJdLHsiYWNsIjoicHVibGljLXJlYWQifSxbInN0YXJ0cy13aXRoIiwiJENvbnRlbnQtVHlwZSIsIiJdLFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsNTI0Mjg4MDAwXV19',
      signature: 'RM+esk+XnqwklvgBY2I/7r5YMb8=',
    },
    development: {
      endpoint: 'https://remoov-development.s3.amazonaws.com/',
      awsKey: 'AKIAIPRQBRPBHXO7ERRA',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAzMC0xMi0zMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W3siYnVja2V0IjoicmVtb292LWRldmVsb3BtZW50In0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCIiXSx7ImFjbCI6InB1YmxpYy1yZWFkIn0sWyJzdGFydHMtd2l0aCIsIiRDb250ZW50LVR5cGUiLCIiXSxbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwwLDUyNDI4ODAwMF1dfQ==',
      signature: 'BbnzwhqUAg2SJJ3plL0XmhutBug=',
    },
    staging: {
      endpoint: 'https://remoov-staging.s3.amazonaws.com/',
      awsKey: 'AKIAIPRQBRPBHXO7ERRA',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAzMC0xMi0zMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W3siYnVja2V0IjoicmVtb292LXN0YWdpbmcifSxbInN0YXJ0cy13aXRoIiwiJGtleSIsIiJdLHsiYWNsIjoicHVibGljLXJlYWQifSxbInN0YXJ0cy13aXRoIiwiJENvbnRlbnQtVHlwZSIsIiJdLFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsNTI0Mjg4MDAwXV19',
      signature: 'aaa1GnlNKkN8gvibfgoBP50VBHg=',
    },
    production: {
      endpoint: 'https://remoov-production.s3.amazonaws.com/',
      awsKey: 'AKIAIPRQBRPBHXO7ERRA',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAzMC0xMi0zMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W3siYnVja2V0IjoicmVtb292LXByb2R1Y3Rpb24ifSxbInN0YXJ0cy13aXRoIiwiJGtleSIsIiJdLHsiYWNsIjoicHVibGljLXJlYWQifSxbInN0YXJ0cy13aXRoIiwiJENvbnRlbnQtVHlwZSIsIiJdLFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsNTI0Mjg4MDAwXV19',
      signature: 'wybMS8IxwxRfztEbcGdQARTBOHY=',
    },
  }[ENV()];
}
export function cookieKey(): string {
  return Constants.ENV_VAR[ENV()].COOKIE_KEY;
}
export function corsEndpoints(): (string | RegExp)[] {
  const cors = [
    ...domainToSubdomains('remoovit.com'),
    ...domainToSubdomains('remoov.com'),
    ...domainToSubdomains('thelocalflea.com'),
    ...domainToSubdomains('reperch.com'),
    ...domainToSubdomains('myshopify.dev'),
    hostEndpoint(),
    'https://remoov-redesign-staging.webflow.io',
    'https://remoov-redesign.webflow.io',
    'http://thelocalflea.com',
    'https://thelocalflea.com',
    'http://reperch.com',
    'https://reperch.com',
    'http://tryremoov.com',
    'https://tryremoov.com',
  ];
  return {
    test: cors,
    development: cors.concat(
      [
        'http://localhost:3000',
        'http://localhost:5001',
        'http://localhost:6006',
        'http://0.0.0.0:3000',
        'http://0.0.0.0:5001',
        'http://0.0.0.0:6006',
        'https://remoov-development.s3.amazonaws.com',
      ].filter(Boolean),
    ),
    staging: cors.concat(['http://localhost:8080']),
    production: cors.concat(['http://localhost:8080']),
  }[ENV()];
  function domainToSubdomains(domain: string): (string | RegExp)[] {
    return [
      `http://${domain}`,
      `https://${domain}`,
      new RegExp(`\\.${domain.split('.').join('\\.')}`),
    ];
  }
}
export function throwIfNotDevelopment(message: string): void {
  if (!isDevelopment()) {
    throw new Error(`Only accessible in development: '${message}'.`);
  }
}
export function log(...messages: unknown[]) {
  if (ENV() === 'test') return;

  /* eslint-disable no-console */
  console.log(...messages);
  /* eslint-enable no-console */
}
export function sentryBrowserTraceSampleRate(): number {
  return Constants.ENV_VAR[ENV()].SENTRY_TRACE_SAMPLE_RATE;
}
export function awsFilesUrlPrefix(): string {
  return `${s3Params().endpoint}files`;
}
export function remoovNodeEndpoint(): string {
  return (
    {
      test: `http://test.remoovit.com`,
      development: `http://localhost:5001`,
      staging: Constants.ENV_VAR.staging.REMOOV_NODE_URL,
      production: Constants.ENV_VAR.production.REMOOV_NODE_URL,
    }[ENV()] || `http://localhost:5001`
  );
}
export function remoovShortDomain(): string {
  return (
    {
      test: `http://t.rmv.my`,
      development: `http://localhost:5001`,
      staging: 'https://s.rmv.my',
      production: 'https://rmv.my',
    }[ENV()] || `http://localhost:5001`
  );
}
export function remoovNodeWebsocketPort() {
  return 5005;
}
export function remoovNodeWebsocketEndpoint() {
  const PORT = remoovNodeWebsocketPort();
  return (
    {
      test: `http://localhost:${PORT}`,
      development: `http://localhost:${PORT}`,
      staging: `https://stagingapp.remoovit.com:`,
      production: `https://app.remoovit.com:`,
    }[ENV()] || `http://localhost:${PORT}`
  );
}
export function reperchDomain(): string {
  return Constants.ENV_VAR[ENV()].REPERCH_URL;
}
export function jwtKey() {
  if (!process.env.SECRET_KEY_BASE) throw Error('JWT key not present');
  return process.env.SECRET_KEY_BASE;
}
