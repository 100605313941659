import {
  Market,
  getMarketReperchFacebookLink,
  getMarketReperchInstagramLink,
  getMarketReperchPinterestLink,
  getMarketReperchTwitterLink,
  removeUndefinedValues,
} from '@remoov/functional';
import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {Button, Col, Image, Row} from 'react-bootstrap';
import {
  ArrowRightWhite,
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TlfHeaderBackground1,
  TlfHeaderBackground2,
  TlfHeaderBackground3,
  TlfHeaderBackground4,
  TlfLogoGreen,
  TwitterIcon,
} from './Assets';
export {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Image,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
export * from './Assets';
export const TLF_AFFILIATE_URL = 'https://thelocalflea.refersion.com/affiliate/registration';
export function TlfLayout({children}: {children: ReactNode}) {
  return (
    <div className='tlf-layout'>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
export function Header() {
  const style: StyleObject = {
    header: {
      backgroundColor: '#FFFFFF',
      padding: '17px 0',
    },
    headerContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linksContainer: {
      color: '#000000',
      fontWeight: 500,
      fontSize: 16,
      display: 'flex',
      flexGrow: 1,
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
  };
  return (
    <div id='tlf-header' style={style.header}>
      <ReperchContainer style={style.headerContainer}>
        <div className='tlf-logo'>
          <Image src={TlfLogoGreen} />
        </div>
        <div className='tlf-header-links' style={style.linksContainer}>
          <a href='https://remoovit.com' target='_blank' rel='noreferrer'>
            Sell With Us
          </a>
        </div>
      </ReperchContainer>
    </div>
  );
}
export function TlfCarousel() {
  const items = [
    TlfHeaderBackground1,
    TlfHeaderBackground2,
    TlfHeaderBackground3,
    TlfHeaderBackground4,
  ];
  const [index, setIndex] = useState(0);
  const goLeft = () => setIndex((index + items.length - 1) % items.length);
  const goRight = () => setIndex((index + +1) % items.length);
  useEffect(() => {
    const id = setTimeout(goRight, 5000);
    return () => clearTimeout(id);
  }, [index]);
  return (
    <div className='tlf-header-carousel'>
      <ReperchContainer
        style={{
          position: 'relative',
          width: '100%',
        }}
      >
        <TlfHeaderCarousel {...{items, index, goLeft, goRight}} />
        <div
          className='header-copy'
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#FFFFFF',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          The largest selection of quality pre-owned items for your home
        </div>
      </ReperchContainer>
    </div>
  );
}
export function Footer() {
  const style: StyleObject = {
    container: {
      display: 'flex',
      flexFlow: 'column wrap',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 30,
    },
    socialMediasContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    theFleaMarket: {
      color: '#000000',
      textAlign: 'center',
      width: '100%',
    },
  };
  return (
    <div id='tlf-footer'>
      <ReperchContainer style={style.container}>
        <div className='social-medias-container' style={style.socialMediasContainer}>
          <a href={getMarketReperchTwitterLink(Market.BAY_AREA)} target='_blank' rel='noreferrer'>
            <Image src={TwitterIcon} />
          </a>
          <a href={getMarketReperchFacebookLink(Market.BAY_AREA)} target='_blank' rel='noreferrer'>
            <Image src={FacebookIcon} />
          </a>
          <a href={getMarketReperchPinterestLink(Market.BAY_AREA)} target='_blank' rel='noreferrer'>
            <Image src={PinterestIcon} />
          </a>
          <a href={getMarketReperchInstagramLink(Market.BAY_AREA)} target='_blank' rel='noreferrer'>
            <Image src={InstagramIcon} />
          </a>
        </div>
        <div className='the-flet-market' style={style.theFleaMarket}>
          © The Local Flea Inc. {new Date().getFullYear()}
        </div>
      </ReperchContainer>
    </div>
  );
}
export function Section({
  children,
  style,
  container,
  ...props
}: {
  children?: ReactNode;
  style?: CSSProperties;
  container?: unknown;
}) {
  const style2: StyleObject = {
    div: {
      padding: '50px 0',
      width: '100%',
      ...style,
    },
    container: {
      width: container ? '98%' : '100%',
      maxWidth: container ? 1024 : null,
      margin: container ? '0 auto' : null,
    },
  };
  return (
    <div {...props} style={style2.div}>
      <div style={style2.container}>{children}</div>
    </div>
  );
}
export function SectionHeadline({
  subheadline,
  className: classNameProp,
  style: styleProp,
  ...props
}: {
  subheadline: string;
  className: string;
  style: CSSProperties;
}) {
  const style: StyleObject = {
    container: {
      color: '#333',
    },
    h2: {
      fontWeight: 'bold',
      ...styleProp,
    },
    subheadline: {
      color: '#4F4F4F',
      fontSize: 15,
      textAlign: 'center',
    },
  };
  const className = ['tlf-section-headline']
    .concat([classNameProp || ''])
    .filter(Boolean)
    .join(' ');
  return (
    <div className={className} style={style.container}>
      <h2 {...props} style={style.h2} />
      {subheadline && (
        <div className='subheadline' style={style.subheadline}>
          {subheadline}
        </div>
      )}
    </div>
  );
}
export function Card({
  icon,
  title,
  style: styleProp,
  ...otherProps
}: {
  icon: string;
  title: string;
  style: CSSProperties;
}) {
  const style: StyleObject = {
    container: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexFlow: 'column wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 30,
      textAlign: 'center',
      ...styleProp,
    },
    icon: {
      marginBottom: 15,
      height: 50,
    },
    title: {
      color: '#000000',
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 15,
    },
    text: {
      color: '#000000',
      fontSize: 15,
    },
  };
  return (
    <div style={style.container} className='tlf-card'>
      <div>
        <Image src={icon} style={style.icon} />
      </div>
      {title && <div style={style.title}>{title}</div>}
      <div style={style.text} {...otherProps} />
    </div>
  );
}
export function TlfButtonLink({
  className,
  style,
  href,
  children,
}: {
  className: string;
  style: CSSProperties;
  href: string;
  children: ReactNode;
}) {
  const className2 = ['tlf-button-link'].concat(className ? [className] : []).join(' ');
  const style2 = {
    ...style,
    backgroundColor: 'rgba(29, 133, 132, 0.8)',
    color: '#FFF',
    borderRadius: 0,
  };
  return (
    <a className={className2} href={href} target='_blank' rel='noreferrer'>
      <Button style={style2}>{children}</Button>
    </a>
  );
}
export function ReperchContainer({
  id,
  className,
  style,
  children,
}: {
  id?: string;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}) {
  return (
    <div
      {...removeUndefinedValues({id, className})}
      style={{
        width: '95%',
        maxWidth: 1280,
        marginRight: 'auto',
        marginLeft: 'auto',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
function TlfHeaderCarousel({
  items,
  index,
  goRight,
  goLeft,
}: {
  items: string[];
  index: number;
  goRight: GenericFunction;
  goLeft: GenericFunction;
}) {
  const marginLeft = `-${(index % 4) * 100}%`;
  return (
    <div
      style={{
        width: '100%',
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <div style={{overflow: 'hidden'}}>
        <Row className='carousel-wrapper' style={{marginLeft, marginBottom: 0}}>
          {items.map((item, i) => (
            <TlfCarouselItem key={i} src={item} />
          ))}
        </Row>
      </div>
      <div
        className='arrow-to-left'
        onClick={goLeft}
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: '40%',
          backgroundColor: 'transparent',
          borderRadius: '25px',
          textAlign: 'left',
        }}
      >
        <img
          className='arrow'
          style={{
            transform: 'rotate(180deg)',
            WebkitTransform: 'rotate(180deg)',
          }}
          src={ArrowRightWhite}
        />
      </div>
      <div
        className='arrow-to-right'
        onClick={goRight}
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: '40%',
          backgroundColor: 'transparent',
          borderRadius: '25px',
          textAlign: 'right',
        }}
      >
        <img className='arrow' src={ArrowRightWhite} />
      </div>
    </div>
  );
}
function TlfCarouselItem({src}: {src: string}) {
  const style: StyleObject = {
    col: {
      padding: 0,
      height: '100%',
    },
    container: {
      height: '100%',
      width: '100%',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  };
  return (
    <Col xs={3} style={style.col}>
      <div style={style.container}>
        <img src={src} style={style.image} />
      </div>
    </Col>
  );
}
